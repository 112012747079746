<!--
 * @Descripttion: 说明
 * @Author: liuzhixiang
 * @Date: 2021-07-14 11:28:30
 * @LastEditors: liuzhixiang
 * @LastEditTime: 2021-07-14 12:00:28
-->

<template>
  <div class="payment" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar title="支付" fixed left-arrow @click-left="onClickLeft" />
    </template>
    <!-- <navBar title="支付" left="1"></navBar> -->
    <!-- 价格 -->
    <div class="price_top">
      <div class="xy">需支付金额（元）</div>
      <div class="price">
        ￥
        <span> 398 </span>
      </div>
    </div>
    <!-- 支付方式 -->
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          clickable
          v-for="p in pays"
          :key="p.value"
          @click="radio = p.value"
        >
          <template #title>
            <div class="cell_title">
              <img :src="p.img" alt="" />{{ p.lable }}
            </div>
          </template>
          <template #right-icon>
            <van-radio :name="p.value" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="btn_pay">立即支付</div>
  </div>
</template>

<script>
import navBar from "../components/navBar.vue";
export default {
  data() {
    return {
      pays: [
        {
          img: require("../../../assets/ShopImg/icon_pld.png"),
          value: "1",
          lable: "普利点支付",
        },
        {
          img: require("../../../assets/ShopImg/icon_alipay.png"),
          value: "2",
          lable: "支付宝",
        },
        {
          img: require("../../../assets/ShopImg/icon_wechat.png"),
          value: "3",
          lable: "微信",
        },
      ],
      radio: false,
      isWxApplets: true,
    };
  },
  components: {
    navBar,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "支付";
      this.isWxApplets = false;
    }
  },
  mounted() {},
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.payment {
  .price_top {
    text-align: center;
    background-color: #fff;
    margin-bottom: 0.625rem;
    .xy {
      padding: 2.4375rem 0 0.625rem;
      font-size: 15px;
      color: #333333;
    }
    .price {
      font-size: 16px;
      padding-bottom: 2.5rem;
      span {
        font-size: 30px;
        color: #333333;
        font-weight: bold;
      }
    }
  }
  .cell_title {
    display: flex;
    align-items: center;
    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.6875rem;
    }
  }
  .btn_pay {
    width: 17.5rem;
    height: 2.625rem;
    line-height: 2.625rem;
    border-radius: 30px;
    text-align: center;
    margin: 6.5rem auto 1.25rem;
    background: linear-gradient(99deg, #fc7134 0%, #f54024 100%);
    font-size: 15px;
    color: #ffffff;
  }
}
.van-cell-group {
  &::after {
    border: none;
  }
  .van-cell {
    &::after {
      border: none;
    }
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
